import './App.css';
import { Routing } from "./appRoutes";
// import ConfiguratorViewer from './components/ConfiguratorViewer/ConfiguratorViewer'

function App() {
  return (
      // <ConfiguratorViewer />
      <Routing />
  );
}

export default App;
