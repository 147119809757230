import { Link } from "react-router-dom";
import styles from "./assets/scss/home.module.scss";

export default function Home() {
  const scenesData = [
    {
      id: 1,
      link: "/1",
      imgSrc: "thumbnails/Fjords Anne.png",
      title: "Fjords Anne",
    },
    {
      id: 2,
      link: "/2",
      imgSrc: "thumbnails/Fjords Loft.png",
      title: "Fjords Loft",
    },
    {
      id: 3,
      link: "/3",
      imgSrc: "thumbnails/Fjords Dublin.png",
      title: "Fjords Dublin",
    },
    {
      id: 4,
      link: "/4",
      imgSrc: "thumbnails/Fjords Dallas.png",
      title: "Fjords Dallas",
    },
    {
      id: 5,
      link: "/5",
      imgSrc: "thumbnails/Fjords Falcon.png",
      title: "Fjords Falcon",
    },
    {
      id: 6,
      link: "/6",
      imgSrc: "thumbnails/Fjords Bergen.png",
      title: "Fjords Bergen",
    },
    {
      id: 7,
      link: "/7",
      imgSrc: "thumbnails/Fjords Mustang.png",
      title: "Fjords Mustang",
    },
    {
      id: 8,
      link: "/8",
      imgSrc: "thumbnails/Fjords Memphis.png",
      title: "Fjords Memphis",
    },
    {
      id: 9,
      link: "/9",
      imgSrc: "thumbnails/Fjords Major.png",
      title: "Fjords Major",
    },
    {
      id: 10,
      link: "/10",
      imgSrc: "thumbnails/Fjords Harstad.png",
      title: "Fjords Harstad",
    },
    {
      id: 11,
      link: "/11",
      imgSrc: "thumbnails/Fjords Paris.png",
      title: "Fjords Paris",
    },
    {
      id: 12,
      link: "/12",
      imgSrc: "thumbnails/Fjords Queen.png",
      title: "Fjords Queen",
    },
    {
      id: 13,
      link: "/13",
      imgSrc: "thumbnails/Fjords Bravo.png",
      title: "Fjords Bravo",
    },
    {
      id: 14,
      link: "/14",
      imgSrc: "thumbnails/Fjords Stockholm.png",
      title: "Fjords Stockholm",
    },
    {
      id: 15,
      link: "/15",
      imgSrc: "thumbnails/Fjords Monte Carlo.png",
      title: "Fjords Monte Carlo",
    },
    {
      id: 16,
      link: "/16",
      imgSrc: "thumbnails/Fjords Nice.png",
      title: "Fjords Nice",
    },
    {
      id: 17,
      link: "/17",
      imgSrc: "thumbnails/Fjords Baloo.png",
      title: "Fjords Baloo",
    },
    {
      id: 18,
      link: "/18",
      imgSrc: "thumbnails/Fjords Magnus.png",
      title: "Fjords Magnus",
    },
    {
      id: 19,
      link: "/19",
      imgSrc: "thumbnails/Fjords Venice.png",
      title: "Fjords Venice",
    },
    {
      id: 20,
      link: "/20",
      imgSrc: "thumbnails/Fjords Ulstein.png",
      title: "Fjords Ulstein",
    },
    {
      id: 21,
      link: "/21",
      imgSrc: "thumbnails/Fjords Rome.png",
      title: "Fjords Rome",
    },
    {
      id: 22,
      link: "/22",
      imgSrc: "thumbnails/Fjords Milan Lift Chair.png",
      title: "Fjords Milan Lift Chair",
    },
    {
      id: 23,
      link: "/23",
      imgSrc: "thumbnails/Fjords Admiral.png",
      title: "Fjords Admiral C",
    },
    {
      id: 24,
      link: "/24",
      imgSrc: "thumbnails/Fjords Skagen Low Back.png",
      title: "Fjords Skagen Low Back",
    },
    {
      id: 25,
      link: "/25",
      imgSrc: "thumbnails/Fjords Villa.png",
      title: "Fjords Villa",
    },
    {
      id: 26,
      link: "/26",
      imgSrc: "thumbnails/Fjords Nordic.png",
      title: "Fjords Nordic",
    },
    {
      id: 27,
      link: "/27",
      imgSrc: "thumbnails/Fjords Endless.png",
      title: "Fjords Endless",
    },
    {
      id: 28,
      link: "/28",
      imgSrc: "thumbnails/Fjords Verona.png",
      title: "Fjords Verona",
    },
    {
      id: 29,
      link: "/29",
      imgSrc: "thumbnails/Fjords Blinken.png",
      title: "Fjords Blinken",
    },
    {
      id: 30,
      link: "/30",
      imgSrc: "thumbnails/Fjords ulstein WS.png",
      title: "Fjords Ulstein WS",
    },
    {
      id: 31,
      link: "/31",
      imgSrc: "thumbnails/Fjords milan.png",
      title: "Fjords Milan",
    },
    {
      id: 32,
      link: "/32",
      imgSrc: "thumbnails/Fjords Skagen.JPEG",
      title: "Fjords Skagen High Back",
    },
    {
      id: 33,
      link: "/33",
      imgSrc: "thumbnails/Fjords MilanWs.JPEG",
      title: "Fjords Milan WS",
    },
    {
      id: 34,
      link: "/34",
      imgSrc: "thumbnails/Fjords Bo.png",
      title: "Fjords Bo",
    },
    {
      id: 35,
      link: "/35",
      imgSrc: "thumbnails/Fjords Harshtad Ws.png",
      title: "Fjords Harstad WS",
    },
    {
      id: 36,
      link: "/36",
      imgSrc: "thumbnails/Fjords Helsinki.png",
      title: "Fjords Helsinki",
    },
    {
      id: 37,
      link: "/37",
      imgSrc: "thumbnails/Fjords Finn.png",
      title: "Fjords Finn",
    },
    {
      id: 38,
      link: "/38",
      imgSrc: "thumbnails/Fjords Oslo.png",
      title: "Fjords Oslo",
    },
    {
      id: 39,
      link: "/39",
      imgSrc: "thumbnails/Fjords Venice WS.png",
      title: "Fjords Venice WS",
    },
    {
      id: 40,
      link: "/40",
      imgSrc: "thumbnails/Fjords Jakob.png",
      title: "Fjords Jakob",
    },
    {
      id: 41,
      link: "/41",
      imgSrc: "thumbnails/Fjords Urban.png",
      title: "Fjords Urban",
    },
    {
      id: 42,
      link: "/42",
      imgSrc: "thumbnails/Fjords Admiral R.png",
      title: "Fjords Admiral R",
    },
    {
      id: 43,
      link: "/43",
      imgSrc: "thumbnails/Fjords Senator R.png",
      title: "Fjords Senator R",
    },
    {
      id: 44,
      link: "/44",
      imgSrc: "thumbnails/Fjords Muldal.png",
      title: "Fjords Muldal",
    },
    {
      id: 45,
      link: "/45",
      imgSrc: "thumbnails/Fjords Oskar.png",
      title: "Fjords Oskar",
    },
    {
      id: 46,
      link: "/46",
      imgSrc: "thumbnails/Fjords Rome WS.png",
      title: "Fjords Rome WS",
    },
    {
      id: 47,
      link: "/47",
      imgSrc: "thumbnails/Fjords Austin Lift.png",
      title: "Fjords Austin Lift",
    },
    {
      id: 48,
      link: "/48",
      imgSrc: "thumbnails/Fjords Tampa.png",
      title: "Fjords Tampa",
    },
    {
      id: 49,
      link: "/49",
      imgSrc: "thumbnails/Fjords Regent.png",
      title: "Fjords Regent",
    },
    {
      id: 50,
      link: "/50",
      imgSrc: "thumbnails/Fjords Axel.png",
      title: "Fjords Axel",
    }
  ];

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src="logo.png" alt="" />
        </div>
        <div className={styles.page_title_wrapper}>
          <h1 className={styles.page_title}>
            Configure your Fjords product.
          </h1>
          <p className={styles.page_subtitle}>
            Click on the product and start your configuration.
          </p>
        </div>
      </header>
      <main>
        <div className={styles.gallary}>
          <div className={styles.row}>
            {scenesData &&
              scenesData.map((scenes) => {
                return (
                  <Link
                    key={scenes.id}
                    to={scenes.link}
                    className={styles.thumbnail}
                  >
                    <div className={styles.hideOverflow}>
                      <img
                        className={styles.thumbnail_img}
                        src={scenes.imgSrc}
                        alt={scenes.title}
                      />
                    </div>
                    <div className={styles.name}>{scenes.title}</div>
                  </Link>
                );
              })}
          </div>
        </div>
      </main>
    </>
  );
}
