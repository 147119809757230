import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Loader from './components/Loader/Loader';
import Home from "./homePage";
export const CONFIGURATOR = "/1";

const ConfiguratorViewer = lazy(() => import("./components/ConfiguratorViewer/ConfiguratorViewer"));

const routeMapFunc = (
  { exact = true, path, Component, componentProps, routes },
  idx
) => {
  return (
    <Route
      path={path}
      exact={exact}
      element={<Component {...componentProps} />}
      key={idx}
    >
      {routes?.map(routeMapFunc)}
    </Route>
  );
};

export const routes = [
  // { path: CONFIGURATOR, Component: ConfiguratorViewer, key: "viewer" },
  { path: '/', Component: Home, key: 'home'},
  { path: '/:productId', Component: ConfiguratorViewer, key: 'viewer'}
];

export const Routing = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader zIndex={1000} loading={true} className="bg-white" position="fixed" />}>
        <Routes>{routes.map(routeMapFunc)}</Routes>
      </Suspense>
    </ErrorBoundary>
  );
};
